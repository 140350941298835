import './app/config/composition-api';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueFormulate from '@braid/vue-formulate';
import PortalVue from 'portal-vue';
import VueToastr from 'vue-toastr';
import { VTooltip, VPopover } from 'v-tooltip';
import VCalendar from 'v-calendar';
import { FormulateMultiselect, FormulateTreeselect, FormulateDatepicker } from '@/modules/asset/components/index';
import App from './app/views/App.vue';
import router from './app/router';
import store from './app/store';

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: process.env.VUE_APP_VERSION,
        integrations: [
            new Integrations.Vue({
                Vue,
                attachProps: true,
                logErrors: true,
            }),
        ],
        beforeSend(error) {
            if (error && error.message && error.message.match(/status code 401/i)) {
                return null; // No event is sent for unauthenticated requests
            }
            return error;
        },
    });
}

Vue.use(VueToastr, {
    defaultTimeout: 5000,
    defaultPosition: 'toast-bottom-right',
});

Vue.config.productionTip = false;

Vue.use(PerfectScrollbar);
Vue.use(PortalVue);
Vue.directive('tooltip', VTooltip);
Vue.component('v-popover', VPopover);

Vue.use(VCalendar, {
    datePicker: {
        popover: {
            visibility: 'focus',
        },
    },
});
Vue.use(VueFormulate, {
    classes: {
        label: ['text-xs', 'text-neutral-700', 'uppercase', 'font-bold', 'tracking-wide'],
        help: ['text-xs', 'text-neutral-400', 'mb-2'],
    },
    library: {
        treeselect: { classification: 'select', component: FormulateTreeselect },
        multiselect: { classification: 'select', component: FormulateMultiselect },
        datepicker: { classification: 'date', component: FormulateDatepicker },
    },
});

new Vue({
    router,
    store: store.original,
    render: (h: any) => h(App),
} as any).$mount('#app');
