















import { defineComponent } from '@vue/composition-api';
import NavBar from './Header.vue';
import SideMenuBar from './SideMenuBar.vue';
import PageHeader from './PageHeader.vue';

export default defineComponent({
    name: 'BaseLayout',
    components: { PageHeader, NavBar, SideMenuBar },
});
