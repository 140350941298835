import { useAxios } from '@vue-composable/axios';
import { defineModule } from 'direct-vuex';
import { clone, includes } from 'ramda';
import { moduleActionContext } from '@/app/store';
import { UserRoles } from '@/app/constants/user-roles';
import FeaturesAPI from '../api/features';

export interface AuthState {
    user: any;
    features: Record<string, boolean>;
    keycloakToken: any;
    keycloakRefreshToken: any;
}

const authModule = defineModule({
    namespaced: true,
    state: (): AuthState => {
        return {
            user: null,
            features: {},
            keycloakToken: null,
            keycloakRefreshToken: null,
        };
    },
    mutations: {
        CLEAR_USER(state: AuthState) {
            state.user = null;
        },
        SET_USER(state: AuthState, newUser: any) {
            state.user = clone(newUser);
        },
        SET_FEATURES(state: AuthState, features: any) {
            state.features = clone(features);
        },
        CLEAR_KEYCLOAK_TOKEN(state: AuthState) {
            state.keycloakToken = null;
        },
        SET_KEYCLOAK_TOKEN(state: AuthState, token: any) {
            state.keycloakToken = token;
        },
        SET_KEYCLOAK_REFRESH_TOKEN(state: AuthState, refreshToken: any) {
            state.keycloakRefreshToken = refreshToken;
        },
    },
    getters: {
        isAuthenticated: (state: AuthState) => !!state.user,
        isEnabled: (state: AuthState) => (feature: string) => !!state.features[feature],
        hasRole: (state: AuthState) => (role: UserRoles) => includes(role, state.user.roles),
        getKeycloakToken: (state: AuthState) => state.keycloakToken,
    },
    actions: {
        async loadFeatures({ commit }) {
            const { exec } = useAxios(true);
            await exec(FeaturesAPI.getFeatures()).then((result) => {
                if (result && result.data) {
                    commit('SET_FEATURES', result.data);
                }
            });
        },
    },
});

export default authModule;
export const authModuleActionContext = (context: any) => moduleActionContext(context, authModule);
