






import { defineComponent, computed, ref, provide } from '@vue/composition-api';
import Auth from '@/modules/auth/api/auth';
import store from '@/app/store';
import Keycloak from '@/modules/auth/api/keycloak';
import { EmptyLayout, selectLayout } from '../layout';

export default defineComponent({
    name: 'App',
    setup(props, { root }) {
        const isGuest = computed(
            () => store.state.auth.user && !store.state.auth.user.organisationId && Keycloak.isEnabled(),
        );
        const isNotVerified = computed(
            () =>
                store.state.auth.user &&
                store.state.auth.user.organisationId &&
                !store.state.auth.user.isVerified &&
                Keycloak.isEnabled(),
        );
        const layout = computed(() => (isGuest.value ? EmptyLayout : selectLayout(root.$route.name)));
        const isReady = ref(false);
        const { isEnabled } = store.getters.auth;
        provide('isEnabled', isEnabled);

        // If there's no user loaded, try to fetch it
        if (!store.state.auth.user) {
            Auth.user()
                .then(({ data }) => {
                    store.commit.auth.SET_USER(data);
                    if (isGuest.value) {
                        root.$router.push({ name: 'organization-registration' });
                    } else if (isNotVerified.value) {
                        root.$router.push({ name: 'unverified-organization' });
                    } else {
                        store.dispatch.auth.loadFeatures();
                        store.dispatch.dataModel.loadDomains();
                        store.dispatch.notificationEngine.fetchNotifications();
                    }
                    isReady.value = true;
                })
                .catch(() => {
                    // root.$router.push({ name: 'login' });
                    isReady.value = true;
                });
        } else {
            if (isGuest.value) {
                root.$router.push({ name: 'organization-registration' });
            } else if (isNotVerified) {
                root.$router.push({ name: 'unverified-organization' });
            } else {
                store.dispatch.dataModel.loadDomains();
                store.dispatch.notificationEngine.fetchNotifications();
            }
        }

        return { layout, isReady };
    },
});
