

















































































































































































import { InputErrorIcon, OnClickOutside, Scrollbar, TwButton } from '@/app/components';
import { S } from '@/app/utilities';
import { SearchIcon } from '@vue-hero-icons/outline';
import { CheckIcon, PlusCircleIcon, SelectorIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    name: 'AdvancedSelect',
    model: {
        prop: 'selected',
        event: 'update-selected',
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        keyField: {
            type: String,
            default: 'label',
        },
        labelField: {
            type: String,
            default: 'label',
        },
        selected: {
            type: [Array, String, Number],
            default: null,
        },
        rounded: {
            type: String,
            default: 'rounded',
        },
        height: {
            type: String,
            default: 'h-8',
        },
        leadingLabel: {
            type: String,
            default: null,
        },
        leadingLabelWidth: {
            type: String,
            default: 'w-36',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: null,
        },
        fullError: {
            type: Boolean,
            default: true,
        },
        errorColour: {
            type: String,
            default: 'text-red-700',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        expandInPlace: {
            type: Boolean,
            default: false,
        },
        noResultsMessage: {
            type: String,
        },
        maxSelectedValues: {
            type: Number,
            default: 0, // Zero maxSelectedValues means that if multiple input is selected, no limit to max selected values will be applied
        },
        noOfItems: {
            type: Number,
            required: true,
        },
        allowCustom: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        OnClickOutside,
        InputErrorIcon,
        SearchIcon,
        TwButton,
        Scrollbar,
        CheckIcon,
        SelectorIcon,
        PlusCircleIcon,
    },

    setup(props, { emit }) {
        const isOpen = ref<boolean>(false);
        const selectRef = ref<any>(null);
        const itemsRef = ref<any>();
        const search = ref('');
        const paginationStep = ref(1);

        const selectedItems = computed(() => {
            // figure out the list of selected item full objects (not just their labels)
            const selections = [];
            for (let i = 0; i < allItems.value.length; i++) {
                const item: any = allItems.value[i];

                // if there is a selection and we are dealing with the multiple option
                // we match the item and add it to the list of selections
                if (
                    !R.isNil(props.selected) &&
                    ((props.multiple && (props.selected as any[]).includes(item[props.keyField])) ||
                        (!props.multiple && props.selected === item[props.keyField]))
                ) {
                    selections.push(item);
                }
            }

            return selections;
        });
        const selectedItemsLabels = computed(() => {
            const selections = [];
            if (props.multiple && !R.isNil(props.selected)) {
                for (let i = 0; i < (props.selected as any[]).length; i++) {
                    const selectedItem = props.selected[i];
                    if (props.items.find((item: any) => item[props.keyField] === selectedItem))
                        selections.push(selectedItem);
                }
            } else {
                for (let i = 0; i < props.items.length; i++) {
                    const item: any = props.items[i];
                    if (!props.multiple && props.selected === item[props.keyField]) {
                        selections.push(item[props.labelField]);
                    }
                }
            }
            return selections.length > 0 ? selections.join(', ') : null;
        });

        const close = () => {
            search.value = '';
            paginationStep.value = 1;
            emit('search', search.value);
            isOpen.value = false;
        };

        const selection = (item: any) => {
            let selectedList;
            if (props.multiple) {
                if (!R.isNil(props.selected) && (props.selected as any[]).includes(item[props.keyField])) {
                    const updatedList = [...(props.selected as any[])];
                    updatedList.splice((props.selected as any[]).indexOf(item[props.keyField]), 1);
                    emit('update-selected', updatedList);
                    emit('change', updatedList);
                } else if (
                    !props.maxSelectedValues ||
                    (props.maxSelectedValues && (props.selected as any[]).length < props.maxSelectedValues)
                ) {
                    // If there is an input for maxSelectedValues, then limit will be applied
                    const updatedList = [
                        ...(!R.isNil(props.selected as any[]) ? (props.selected as any[]) : []),
                        item[props.keyField],
                    ];
                    emit('update-selected', updatedList);
                    emit('change', updatedList);
                } else if (props.maxSelectedValues) {
                    const updatedList = [...(props.selected as any[])];
                    updatedList.splice(0, 1, item[props.keyField]);
                    emit('update-selected', updatedList);
                    emit('change', updatedList);
                }
            } else {
                selectedList =
                    R.isNil(props.selected) || props.selected !== item[props.keyField] ? item[props.keyField] : null;
                emit('update-selected', selectedList);
                emit('change', item[props.keyField]);
            }
            if (!props.multiple || props.maxSelectedValues === 1) {
                close();
            }
        };

        const errorsString = computed(() => {
            const errorStrings = [];
            if (!props.errors || props.errors.length === 0) {
                return null;
            }
            if (props.errors.length === 1) {
                return props.errors[0];
            }
            for (let e = 0; e < props.errors.length; e++) {
                const error = props.errors[e];
                errorStrings.push(`<li>${error}</li>`);
            }
            return `<ul>${errorStrings.join('')}</ul>`;
        });

        const isSelectable = (item: any) => {
            if (S.has('selectable', item) && item.selectable === false) {
                return false;
            }
            return true;
        };

        const isSelected = (key: string) => {
            if (props.selected && ['String', 'Number'].includes(R.type(props.selected))) {
                return props.selected === key;
            }

            if (props.selected && R.is(Array, props.selected)) {
                return (props.selected as any[]).includes(key);
            }
            return false;
        };

        const changeSelection = (item: any) => {
            if (isSelectable(item) && !props.disabled) {
                selection(item);
            }
        };

        const start = computed<number>(() => (paginationStep.value - 1) * props.noOfItems);
        const end = computed<number>(() => paginationStep.value * props.noOfItems);

        const allItems = computed(() => {
            if (!props.allowCustom) return props.items;
            const customsArray = R.is(Array, props.selected) ? props.selected : props.selected ? [props.selected] : [];
            const customs = customsArray.reduce((acc: any[], s: any) => {
                if (!props.items.some((i: any) => i[props.keyField] === s)) {
                    const obj = {};
                    obj[props.keyField] = s;
                    obj[props.labelField] = s;
                    obj['selectable'] = true;
                    obj['type'] = 'unknown';
                    acc.push(obj);
                }
                return acc;
            }, []);
            return [...props.items, ...customs];
        });

        const filteredItems = computed(() =>
            allItems.value.filter((item: any) =>
                item[props.labelField].toLowerCase().includes(search.value.toLowerCase()),
            ),
        );

        const sortedItems = computed(() =>
            filteredItems.value.sort((a: any, b: any) => a[props.labelField].localeCompare(b[props.labelField])),
        );

        const visibleItems = computed(() => sortedItems.value.slice(start.value, end.value));

        const enableNextPagination = computed(
            () => filteredItems.value.length > props.noOfItems * paginationStep.value,
        );
        const numOfNextItems = computed(() => {
            if (filteredItems.value.length - paginationStep.value * props.noOfItems > props.noOfItems)
                return props.noOfItems;
            return filteredItems.value.length - paginationStep.value * props.noOfItems;
        });

        const enablePreviousPagination = computed(() => paginationStep.value > 1);

        const loadItems = (next = true) => {
            paginationStep.value = next ? paginationStep.value + 1 : paginationStep.value - 1;
            if (itemsRef.value) itemsRef.value.scrollToTop();
        };

        const searchItems = () => {
            paginationStep.value = 1;
            emit('search', search.value);
        };

        const itemsInfoText = computed(
            () =>
                `Showing ${start.value + 1} to ${
                    end.value > filteredItems.value.length ? filteredItems.value.length : end.value
                } of ${filteredItems.value.length} items`,
        );

        return {
            selectRef,
            selectedItems,
            selectedItemsLabels,
            errorsString,
            isOpen,
            close,
            uuidv4,
            selection,
            isSelected,
            isSelectable,
            changeSelection,
            search,
            paginationStep,
            filteredItems,
            enableNextPagination,
            enablePreviousPagination,
            numOfNextItems,
            loadItems,
            itemsInfoText,
            visibleItems,
            searchItems,
            itemsRef,
            allItems,
        };
    },
});
