import { AxiosRequestConfig } from 'axios';

const resource = '/api/notification';

export default {
    fetch: () => ({ method: 'GET', url: `${resource}` } as AxiosRequestConfig),
    markAsSeen: (id: number) => ({ method: 'PATCH', url: `${resource}/${id}/seen` } as AxiosRequestConfig),
    markAllAsSeen: () => ({ method: 'PATCH', url: `${resource}/seen` } as AxiosRequestConfig),
    delete: (id: number) => ({ method: 'DELETE', url: `${resource}/${id}` } as AxiosRequestConfig),
};
