export const ErrorCodes = {
    400: 'Method not allowed for API harvesting! Use GET or POST!',
    401: 'HTTP Error! Please review your API configuration!',
    402: 'Error while trying to reach the API!',
    403: 'HTTP Error while trying to authenticate to client API',
    404: 'Error while trying to convert api response to json',
    405: 'Time transformation error! Please check your data!',
    406: 'Date/Datetime transformation error! Please check your data and the selected date format!',
    407: 'Cannot reach the desired k-anonymity',
    408: 'Acceptable information loss threshold exceeded',
    409: 'Different level of nesting of the original field compared to the concept of the data model to which it has been mapped.',
    410: 'Possible information loss due to invalid mapping.',
    411: 'No field value appeared in the data for some fields in order to be transformed using this mapping.',
    412: 'Multiple field transformations failed.',
    413: 'Empty API response! Please check your API!',
    500: 'Error while trying to reach MinIO',
    501: 'Missing field from kafka configuration in harvester!',
    502: 'Kafka initialization failed in harvester!',
    503: 'Generic error during transformation',
    504: 'Key error during transformation',
    505: 'Improperly configured MongoDB',
    506: 'Failed to insert data to MongoDB',
    507: 'Improperly configured Elasticsearch',
    508: 'Failed to insert data to Elasticsearch',
    509: 'Generic error during loading',
    510: 'Error while converting csv to json in harvester',
    511: 'Error while converting xml to json in harvester',
    512: 'Generic error during harvester',
    513: 'Transformed dataset has 0 rows',
    514: 'Error while trying to reach Vault',
    515: "Unknown 'method' in 'auth'",
    516: 'Error while constructing API parameters',
    517: "Error while getting filename of 'other' file",
    518: 'Error while trying to embed fields in response',
    519: 'Cleaned dataset has 0 rows',
    520: 'Error while trying to apply a cleaning constraint',
    521: 'Generic error during anonymisation',
    522: 'Key error during anonymisation',
    523: 'File to be harvested not found',
    524: 'Generic error during encryption',
    525: 'Key error during encryption',
    526: 'Failed to decrypt summetric key',
    527: 'Error harvesting CSV file due to bad file encoding',
} as any;
