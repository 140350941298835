






















































import { defineComponent, ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { XCircleIcon, CheckCircleIcon, ExclamationCircleIcon } from '@vue-hero-icons/solid';
import LoaderCircle from '@/app/components/LoaderCircle.vue';
import { ServicesAPI } from '../api';

export default defineComponent({
    name: 'Dashboard',
    components: {
        CheckCircleIcon,
        XCircleIcon,
        ExclamationCircleIcon,
        LoaderCircle,
    },
    setup() {
        const { exec } = useAxios(true);
        const services = ref<any>(null);
        const loadingServices = ref<boolean>(false);

        const getServicesStatus = async () => {
            loadingServices.value = true;
            const res = await exec(ServicesAPI.getAll());
            services.value = res?.data;
            loadingServices.value = false;
        };

        getServicesStatus();

        return {
            loadingServices,
            services,
        };
    },
});
