import { moduleActionContext } from '@/app/store';
import { useAxios } from '@vue-composable/axios';
import { defineModule } from 'direct-vuex';
import * as R from 'ramda';
import { clone } from 'ramda';
import { NotificationAPI } from '../api';

export interface Notification {
    id: number;
    updatedAt: Date;
    createdAt: Date;
    seenAt: Date | null;
    eventType: string | null;
    userId: number;
    payload: any;
}

export interface NotificationState {
    notifications: Notification[];
}

export const notificationModule = defineModule({
    namespaced: true,
    state: (): NotificationState => {
        return {
            notifications: [],
        };
    },
    mutations: {
        SET_NOTIFICATIONS(state: NotificationState, notifications: Notification[]) {
            state.notifications = clone(notifications);
        },
        APPEND_NOTIFICATION(state: NotificationState, notification: Notification) {
            state.notifications = [notification, ...state.notifications];
        },
        CLEAR_NOTIFICATIONS(state: NotificationState) {
            state.notifications = [];
        },
        async MARK_NOTIFICATION_AS_SEEN(state: NotificationState, notificationId: number) {
            const { exec } = useAxios();
            await exec(NotificationAPI.markAsSeen(notificationId)).then((res: any) => {
                const idx = R.findIndex(R.propEq('id', notificationId), state.notifications as any[]);
                state.notifications[idx].seenAt = res.data.seenAt;
            });
        },
        async MARK_ALL_NOTIFICATIONS_AS_SEEN(state: NotificationState) {
            const { exec } = useAxios();
            await exec(NotificationAPI.markAllAsSeen()).then((res: any) => {
                for (let i = 0; i < res.data.length; i++) {
                    const idx = R.findIndex(R.propEq('id', res.data[i].id), state.notifications as any[]);
                    state.notifications[idx].seenAt = res.data[i].seenAt;
                }
            });
        },
        DELETE_NOTIFICATION(state: NotificationState, id: number) {
            state.notifications = state.notifications.filter((n) => n.id !== id);
        },
        /**
         * Deletes any notifications which refer to a deleted item (e.g. When a Data Check-in job is deleted, all notifications linked to that DCJ are deleted too)
         */
        SET_NOTIFICATIONS_AFTER_REFERENCED_ITEM_DELETE(state: NotificationState, payload: any) {
            state.notifications = state.notifications.filter(
                (n) =>
                    String(n.payload.referenceId) !== payload.referenceId ||
                    n.payload.referenceType !== payload.referenceType,
            );
        },
    },
    actions: {
        fetchNotifications({ state, commit }) {
            const { exec } = useAxios();
            // disable notifications
            // if (!state.notifications.length) {
            //     exec(NotificationAPI.fetch()).then((result: any) => {
            //         if (result && result.data) {
            //             commit('SET_NOTIFICATIONS', result.data);
            //         }
            //     });
            // }
        },
        addNotification({ commit }, notification) {
            if (!!notification && notification.id > 0) {
                commit('APPEND_NOTIFICATION', notification);
            }
        },
        changeSeenAt({ commit }, notificationId: number) {
            if (notificationId) {
                commit('MARK_NOTIFICATION_AS_SEEN', notificationId);
            }
        },
        markAllAsSeen({ commit }) {
            commit('MARK_ALL_NOTIFICATIONS_AS_SEEN');
        },
    },
});

export const notificationModuleActionContext = (context: any) => moduleActionContext(context, notificationModule);
