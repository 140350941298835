







































































import { defineComponent, ref, computed, inject } from '@vue/composition-api';
import dayjs from 'dayjs';
import { useAssetStatus } from '../composable/asset-status';
import { PencilIcon, TrashIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'AssetOverviewCard',
    components: { PencilIcon, TrashIcon },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isFeatureEnabled: any = inject('isEnabled');
        const options = ref<string[]>([]);
        if (isFeatureEnabled('asset.metadata')) {
            options.value.push('edit');
        }
        options.value.push('delete');

        const status = computed(() => props.asset?.status);
        const { label: assetStatusLabel, colour: statusClass } = useAssetStatus(status);

        return {
            dayjs,
            options,
            emit,
            statusClass,
            isFeatureEnabled,
            assetStatusLabel,
        };
    },
});
