<template>
    <html-modal>
        <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div
                    class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full sm:mx-0 sm:h-10 sm:w-10"
                    :class="iconClasses"
                >
                    <svg
                        v-if="icon === 'exclamation'"
                        class="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                    </svg>
                    <svg v-if="icon === 'info'" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg font-medium leading-6 text-neutral-900">
                        {{ title }}
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-neutral-500">{{ message }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-4 py-3 bg-neutral-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm focus:outline-none sm:text-sm sm:leading-5 disabled:opacity-50 disabled:cursor-not-allowed"
                    :class="buttonClasses"
                    :disabled="loading"
                    @click.stop="$emit('ok')"
                >
                    {{ okButtonText }}
                </button>
            </span>
            <span class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-white border rounded-md shadow-sm border-neutral-300 text-neutral-700 hover:text-neutral-500 focus:outline-none focus:border-blue-300 focus:ring sm:text-sm sm:leading-5 disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="loading"
                    @click.stop="$emit('cancel')"
                >
                    {{ cancelButtonText }}
                </button>
            </span>
        </div>
    </html-modal>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import HtmlModal from './HtmlModal.vue';

export default defineComponent({
    name: 'ConfirmModal',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        title: {
            type: String,
            default: 'Delete record',
        },
        message: {
            type: String,
            default:
                'Are you sure you want to delete this record? All the related data will be permanently removed. This action cannot be undone',
        },
        okButtonText: {
            type: String,
            default: 'Delete',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        icon: {
            type: String,
            default: 'exclamation', // exclamation, info
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { HtmlModal },
    setup(props) {
        const iconColors = {
            blue: 'bg-blue-100 text-blue-600',
            green: 'bg-green-100 text-green-600',
            primary: 'bg-primary-100 text-primary-600',
            red: 'bg-red-100 text-red-600',
            secondary: 'bg-secondary-100 text-secondary-600',
            yellow: 'bg-yellow-100 text-yellow-600',
        };
        const buttonColors = {
            blue: 'bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:ring-blue',
            green: 'bg-green-600 hover:bg-green-500 focus:border-green-700 focus:ring-green',
            primary: 'bg-primary-600 hover:bg-primary-500 focus:border-primary-700 focus:ring-primary',
            red: 'bg-red-600 hover:bg-red-500 focus:border-red-700 focus:ring-red',
            secondary: 'bg-secondary-600 hover:bg-secondary-500 focus:border-secondary-700 focus:ring-secondary',
            yellow: 'bg-yellow-600 hover:bg-yellow-500 focus:border-yellow-700 focus:ring-yellow',
        };

        const iconClasses = computed(() => iconColors[props.color]);
        const buttonClasses = computed(() => buttonColors[props.color]);

        return { iconClasses, buttonClasses };
    },
});
</script>
