




























































import { defineComponent, computed, inject } from '@vue/composition-api';
import dayjs from 'dayjs';
import { DotsVerticalIcon } from '@vue-hero-icons/solid';
import { DropdownMenu } from '@/app/components';
import { StatusCode } from '@/modules/asset/constants';

export default defineComponent({
    name: 'ModelAssetOverview',
    components: { DropdownMenu, DotsVerticalIcon },
    props: {
        model: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isFeatureEnabled: any = inject('isEnabled');

        const statusClass = computed(() => {
            if (props.model && props.model.status) {
                switch (props.model.status) {
                    case StatusCode.Archived:
                        return 'text-neutral-700 bg-neutral-100';
                    case StatusCode.Available:
                        return 'text-green-700 bg-green-100';
                    case StatusCode.Deprecated:
                        return 'text-red-700 bg-red-100';
                    default:
                        return 'text-yellow-700 bg-yellow-100';
                }
            }

            return 'text-yellow-700 bg-yellow-100';
        });

        /**
         * If a model is draft, it means that the 'model' prop is not an asset, but a file.
         * As files have status e.g. 'AVAILABLE', we use the 'draft' status as default case,
         * which is triggered when the status is 'AVAILABLE' in this case.
         */
        const status = computed(() => {
            if (props.model && props.model.status) {
                switch (props.model.status) {
                    case StatusCode.Archived:
                        return StatusCode.Archived;
                    case StatusCode.Available:
                        return 'registered';
                    case StatusCode.Deprecated:
                        return StatusCode.Deprecated;
                    default:
                        return 'draft';
                }
            }
            return 'draft';
        });

        const name: any = computed(() => {
            if (status.value !== 'draft') {
                return props.model.name;
            }

            return props.model.filename;
        });

        /**
         * Options which can be applied to each model asset based on its status
         * Draft: Publish, Delete
         * Archived: Restore
         * All other statuses: Edit, Deprecate, Archive
         */
        const options = computed(() => {
            switch (status.value) {
                case 'archived':
                    return [
                        {
                            name: 'Restore',
                            requiresConfirm: true,
                            action: () => {
                                emit('restore', props.model);
                            },
                        },
                    ];
                case 'registered':
                    return [
                        {
                            name: 'Edit',
                            action: () => {
                                emit('edit', props.model);
                            },
                        },
                        {
                            name: 'Deprecate',
                            action: () => {
                                emit('destroy', props.model);
                            },
                        },
                        {
                            name: 'Archive',
                            requiresConfirm: true,
                            action: () => {
                                emit('archive', props.model);
                            },
                        },
                    ];
                case 'draft':
                default:
                    return [
                        {
                            name: 'Publish',
                            action: () => {
                                emit('edit', { id: props.model.id, status: status.value });
                            },
                        },
                        {
                            name: 'Delete',
                            action: () => {
                                emit('delete', props.model);
                            },
                        },
                    ];
            }
        });

        const edit = () => {
            if (status.value === 'draft') {
                emit('edit', { id: props.model.id, status: status.value });
            } else {
                emit('edit', props.model);
            }
        };

        return {
            dayjs,
            options,
            isFeatureEnabled,
            statusClass,
            status,
            StatusCode,
            name,
            edit,
        };
    },
});
