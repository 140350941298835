












































































































































































































































































































import { UploadIcon, CollectionIcon, InboxInIcon, CubeTransparentIcon, TemplateIcon } from '@vue-hero-icons/outline';
import { defineComponent, ref, computed, inject } from '@vue/composition-api';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import { OnClickOutside, TwAvatar, Scrollbar, ConfirmModal } from '@/app/components';
import { useNotifications } from '@/modules/notification/composable/notifications';
import Keycloak from '@/modules/auth/api/keycloak';
import { UserRoles } from '../constants';
import NotificationListItem from '../../modules/notification/components/NotificationListItem.vue';

export default defineComponent({
    name: 'AppHeader',
    components: {
        OnClickOutside,
        TwAvatar,
        Scrollbar,
        NotificationListItem,
        ConfirmModal,
        UploadIcon,
        CollectionIcon,
        InboxInIcon,
        CubeTransparentIcon,
        TemplateIcon,
    },
    setup(props, { root }) {
        const isFeatureEnabled: any = inject('isEnabled');
        const open = ref(false);
        const responsiveOpen = ref(false);
        const isAuthenticated = computed(() => store.getters.auth.isAuthenticated);
        const user = computed(() => store.state.auth.user);
        const isAdmin = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const isModelModerator = computed(() => store.getters.auth.hasRole(UserRoles.ModelModerator));

        const {
            openNotifications,
            closeNotifications,
            notifications,
            numNotifications,
            setupStream,
            viewAllNotifications,
            enableMarkAllAsSeen,
            markAllAsSeen,
            showMarkAllAsSeenModal,
        } = useNotifications(root);

        const close = () => {
            if (open.value) {
                open.value = false;
            }
        };

        setupStream();

        const logout = async () => {
            (root as any).$toastr.removeByType('info');
            store.commit.auth.CLEAR_USER();
            store.commit.notificationEngine.CLEAR_NOTIFICATIONS();
            root.$router.push({ name: 'home' });
            Keycloak.logout();
            await Auth.logout();
        };

        const closeDropdown = () => {
            openNotifications.value = false;
        };

        return {
            open,
            close,
            openNotifications,
            closeNotifications,
            responsiveOpen,
            isAuthenticated,
            user,
            logout,
            isFeatureEnabled,
            notifications,
            numNotifications,
            viewAllNotifications,
            markAllAsSeen,
            enableMarkAllAsSeen,
            closeDropdown,
            showMarkAllAsSeenModal,
            isModelModerator,
            isAdmin,
        };
    },
});
