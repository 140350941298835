

































import { defineComponent, computed } from '@vue/composition-api';
import Card from './Card.vue';

export default defineComponent({
    name: 'FormBlock',
    components: { Card },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: false,
        },
    },
    setup(props, { slots }) {
        const isEmpty = computed(() => !slots.default);

        return { isEmpty };
    },
});
