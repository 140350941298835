export const S = {
    has: (key: string, obj: any): boolean => {
        // eslint-disable-next-line no-prototype-builtins
        return obj && key && obj.hasOwnProperty(key);
    },
    formatCamelCase: (text: string): string => {
        const result = text.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    },
};
