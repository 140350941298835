


























































import { UploadIcon, CollectionIcon, InboxInIcon, CubeTransparentIcon, TemplateIcon } from '@vue-hero-icons/outline';
import store from '@/app/store';
import { UserRoles } from '../constants';
import { defineComponent, inject, computed } from '@vue/composition-api';
export default defineComponent({
    name: 'SideMenuBar',
    components: {
        UploadIcon,
        CollectionIcon,
        InboxInIcon,
        CubeTransparentIcon,
        TemplateIcon,
    },
    setup() {
        const isFeatureEnabled: any = inject('isEnabled');
        const isAdmin = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const isModelModerator = computed(() => store.getters.auth.hasRole(UserRoles.ModelModerator));
        const user = computed(() => store.state.auth.user);

        return {
            isFeatureEnabled,
            isAdmin,
            isModelModerator,
            user,
        };
    },
});
