import { StatusCode } from '@/modules/asset/constants';
import { computed, Ref } from '@vue/composition-api';

export function useAssetStatus(status: Ref<StatusCode>) {
    const label = computed(() => {
        switch (status.value) {
            case StatusCode.Available:
            case StatusCode.Internal:
            case StatusCode.Pending:
                return 'Available';
            default:
                return status.value;
        }
    });
    const colour = computed(() => {
        switch (status.value) {
            case StatusCode.Incomplete:
                return 'text-yellow-700 bg-yellow-100';
            case StatusCode.Uploading:
                return 'text-purple-700 bg-purple-100';
            case StatusCode.Updating:
                return 'text-blue-700 bg-blue-100';
            case StatusCode.Deprecated:
                return 'text-red-700 bg-red-100';
            case StatusCode.Available:
            case StatusCode.Internal:
            case StatusCode.Pending:
                return 'text-green-700 bg-green-100';
            default:
                return 'text-yellow-700 bg-yellow-100';
        }
    });

    return { label, colour };
}
